.home {
  padding: 40px;

  &__table {
    margin: 30px 0;
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}

.MuiDialogContent-root {
  padding-top: 10px !important;
}

.loading {
  text-align: center;
}

@media (max-width: 500px) {
  .home {
    &__search {
      flex-direction: column;

      .MuiAutocomplete-root {
        width: 100%;
      }
    }

    &__pagination {
      flex-direction: column;
    }
  }
}
